@import '../../node_modules/wf-react-component-library/build/styles/_colors.scss';
@import '../../node_modules/wf-react-component-library/build/styles/_icons.scss';

.sticky-wrapper>div {
  background-color: $white;
}

.footer>.primary-button {
  width: 94%;
}

.flex-container-row.po-details {
  font-weight: normal;
  font-size: .85em;
  border: 1px solid $chard;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;
}

.flex-container-row.po-data {
  border-bottom: 1px dotted $chard;
}

.flex-container-row.po-data .flex-container-column {
  padding: 10px 10px;
}

.flex-container-column.pickup-number {
  padding-left: 0px !important;
}

#pickupNumber {
  padding: 5px;
}

.SingleDatePickerInput {
  & .DateInput_input {
    height: 25px;
  }
}

.flex-container-row.po-item-count {
  justify-content: space-between;
  width: 100%;
}

.flex-container-column.item-count {
  padding-right: 10px;
  padding-bottom: 5px;
  justify-content: flex-end;
}

.toggle-items {
  border: none;
  background-color: transparent;
  color: $chard;
  cursor: pointer;
  outline: none;
  padding-right: 20px;
  font-size: 1.25em;
  font-weight: 400;

  &:hover {
    font-weight: 700;
  }
}

.flex-container-row.item-table-container {
  padding: 10px;
  display: none;

  &.show {
    display: flex;
  }

  & .flex-container-column {
    width: 100%;
  }
}

.item-table {
  width: 100%;
  font-weight: normal;
  font-size: 1em;
  border: 1px solid $chard;

  & td {
    border-left: 2px solid $chard;
    padding-left: 5px;
    line-height: 30px;
  }

  & td:first-child {
    border-left: none;
  }
}

.item-table-header {
  text-align: left !important;
  font-size: 1.2em;
  background-color: $tahini;
  color: $chard;

  & td {
    border-bottom: 2px solid $chard;
  }
}

.item-row {
  text-align: left;

  &:nth-child(even) {
    background: $tahini
  }

  & .checkbox-container {
    text-align: center !important;
  }

  &:hover {
    cursor: pointer;
    background-color: $lag;
    color: $tahini;
  }

  &.selected {
    background-color: $lag;
    color: $tahini;
  }
}

.flex-container-row.top-level{
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  & .headline-small {
    padding-bottom: 10px;
    padding-right: 20px;
    text-align: left;
  }
  &.justify-end {
    justify-content: flex-end;
  }
}

#cooler {
  height: 30px !important;
  background-position: calc(100% - .5rem) calc(100% + .1rem) !important;
}

.coolers>.wf-form-element-container {
  padding: 0px !important;
}

.SingleDatePickerInput.SingleDatePickerInput_1 {
  height: 30px;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(195, 195, 195)) !important;

  & .DateInput.DateInput_1 {
    height: 30px;

    & .DateInput_input.DateInput_input_1 {
      height: 20px;
      padding-bottom: 0px;
      padding-top: 5px;
      font-weight: 400 !important;
    }

    & .DateInput_input__focused {
      border-bottom: none !important;
    }
  }
}

.flex-container-row.search-buttons {
  justify-content: flex-end;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
}

.flex-container-row.actions-bar {
  justify-content: space-between;
  display: none;
  padding-right: 10px;

  &.show {
    display: flex;
  }
}

.flex-container-column.xpo-count {
  padding-left: 20px;
  padding-top: 20px;
  font-size: 1.25em;
}

.flex-container-row.search-container {
  & .search {
    width: 450px;
  }

  & .search-icon {
    top: 50%;
  }
}

.flex-container-row.select-all-checkbox {
  justify-content: flex-end;
  padding-right: 20px;
}

.po-header-label {
  padding-right: 0px !important;
}

.approved-icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-top: -3px;
}

.rejected-icon {
  width: 23px;
  height: 23px;
  vertical-align: middle;
  margin-top: -3px;
  background-color: $error;
}

.sort-column {
  cursor: pointer;
  white-space: nowrap !important;
}

.sort-order-image {
  padding-left: 10px;
}