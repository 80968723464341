@import '../../node_modules/wf-react-component-library/build/styles/_colors.scss';

.po-input {
    width: 100%;
    height: 30px;
    border-radius: 2px;
    color: #757575;
    border: 1px solid #E4E4E4;
    font-family: var(--fontCircular);
    font-weight: var(--font-weight-book);
    font-size: 1rem;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 5px;
    padding-right: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #FFFFFF;

    outline: none;
}

.po-input.item-details {
    border-color: $lag;
}

.item-details-row {
    text-align: left;
    & td {
        padding-left: 5px !important;
    }
}

.item-table-header {
    font-size: 1em !important;
    font-weight: bold;
    & td {
        padding-left: 5px !important;
    }
}

.item-details-col {
    padding: 5px 6px 5px !important;
}

.coo .wf-select {
    height: 30px !important;
    background-position: calc(100% - .5rem) calc(100% + .1rem) !important;
}

.coo .wf-form-element-container {
    padding: 0px !important;
    & select {
        font-size: 1.1em;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.qty .wf-form-element-container {
    padding: 0px !important;
    & .wf-input {
        font-size: 1.1em;
        height: 30px !important;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.price .wf-form-element-container {
    padding: 0px !important;
    & .wf-input {
        font-size: 1.1em;
        height: 30px !important;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.overlay {
    z-index: 500 !important;
}

.ocd-item-header {
    font-family: "Corda Std,Georgia,serif";
    font-weight: 500;
    display: block;
    font-size: 2rem;
    line-height: 3rem;
    text-align: left;
    padding: 5px 20px;
    color: $chard;
}

.qty-color {
    background-color: $spoon;
    text-align: center;
}

.weight-color {
    background-color: $spoon;
    text-align: center;
}


.location-color {
    background-color: $spoon;
    text-align: center;
}

.price-color {
    background-color: $spoon;
    text-align: center;
}

.confirmed-header {
    background-color: $tahini;
    text-align: center;
}

.confirmed-color {
    background-color: $white;
    text-align: center;
}

.flex-container-row.po-header-details {
    justify-content: flex-end;
}

.flex-container-column.po-header-details {
    width: 20% !important;
    padding: 0px 0px 10px;
}

.checkbox-container {
    text-align: center !important;
}

input[type="checkbox"]:disabled + span {
  opacity:0.5;
  pointer-events: none;
}

.rejection-note {
    width: 100%
}

.rejection-note-label {
    text-align: center;
    padding: 10px 0px 5px;
    color: $chard;
}

.rejection-note-text-area {
    border-radius: 2px;
    width: 50%;
    color: $lag;
    border: 1px solid $spoon;
    font-family: var(--fontCircular);
    font-weight: var(--font-weight-book);
    font-size: 1rem;
    padding: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    &::placeholder {
        color: $spoon;
    }
    &.error {
        border-bottom-color: $error;
        color: $error;
    }
    & :focus {
        outline: none;
    }
}

.error-msg {
    width: 100%;
    text-align: center;
    padding-top: 5px;
}

.toggle-closed-items {
    border: none;
    background-color: transparent;
    color: #004E36;
    cursor: pointer;
    outline: none;
    padding-right: 20px;
    font-size: .95rem;
    font-weight: 400;
    text-decoration: underline;
    text-align: left;
    padding-left: 20px;
    padding-bottom: 10px;
  }

  .flex-container-column.no-width {
      width: 50% !important;
  }

  .flex-container-row.totals {
    justify-content: space-around;;
  }

  .flex-container-row.hide-show {
    justify-content: flex-start;
  }

  .flex-container-row.header-details {
    justify-content: space-between;
  }
