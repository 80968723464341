@import '../../node_modules/wf-react-component-library/build/styles/_colors.scss';
@import '../../node_modules/wf-react-component-library/build/styles/_icons.scss';

.App {
  text-align: center;
}

.App>.sticky-wrapper {
  z-index: 500;
}

.App .page-header {
  padding: 20px;
  border-bottom: solid 2px $chard;
  justify-content: space-between;
}

.App .headline-serif {
  color: $chard;
}

.App .body-short-form {
  padding-left: 5px;
}

.flex-container-row.search-criteria {
  margin: 20px;
  border: 2px solid $chard;
  background-color: $white;
}

#multiselectContainerReact ._2iA8p44d0WZ-WqRBGcAuEV  {
  width: 500px;
}

#multiselectContainerReact .chip {
  border-radius: 0px !important;
}

// display option list from the top down instead of bottom up
#multiselectContainerReact ._3vLmCG3bB3CM2hhAiQX1tN {
  top: 100%!important;
}

.DateInput_input.DateInput_input_1 {
  color: $lag;
  font-size: 16px;
  font-weight: 700;
}

.DateInput_input {
  width: 80% !important;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: #006F46 !important;
  border: 1px double #006F46 !important;
  color: #fff;
}
.CalendarDay__selected_span {
  background: #e4e4e4 !important;
  border: 1px double #e4e4e4 !important;
  color: #006F46 !important;
}



.flex-container-column.top-level {
  padding: 0px !important;
}

.modal {
  z-index: 4000 !important;
}
