@import '../node_modules/wf-react-component-library/build/styles/_colors.scss';
@import '../node_modules/wf-react-component-library/build/styles/_icons.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-header {
  padding: 20px;
  border-bottom: solid 2px $chard;
  justify-content: space-between;
}

.flex-container-row.actions-bar {
  justify-content: space-between;
  display: none;
  &.show {
    display: flex;
  }
}

.button-padding {
  padding-left: 20px;
}

.last-updated .wf-form-element-label {
  width: 100%;
}

.headline-serif {
  color: $chard;
}

.body-short-form {
  padding-left: 5px;
}

.search-container {
  & .search {
    width: 450px;
  }
  & .search-icon {
    top: 50%;
  }
}


.flex-container-row.item-table-container {
  padding: 0px 20px 20px;
  display: none;
  &.show {
    display: flex;
  }
}

.item-table {
  width: 100%;
  border: 1px solid $chard;
  & td {
    border-left: 2px solid $chard;
    padding-left: 5px;
  }
  & td:first-child {
    border-left: none;
  }
}

.item-row {
  text-align: left;
  &:nth-child(even) {
    background: $tahini
  }
  & .checkbox-container {
    text-align: center !important;
  }
  &:hover {
    cursor: pointer;
    background-color: $lag;
    color: $tahini;
  }
  &.selected {
    background-color: $lag;
    color: $tahini;
  }
}

.checkbox-container {
  padding: 5px;
  & input[type="checkbox"] {
      display: none;
  }
  & input[type="checkbox"] + span {
      display: inline-block;
      position: relative;
      top: -1px;
      width: 20px;
      height: 20px;
      margin: -1px 0px 0 0;
      vertical-align: middle;
      background: white left top no-repeat;
      border: 1px solid $chard;
      cursor: pointer;
  }
  & input[type="checkbox"]:checked + span {
      background: $chard -19px top no-repeat;
  }    
  & input[type="checkbox"] + span {
      margin-right: 4px;
  }
}

.item-details-container {
  visibility: hidden;
  &.show {
    visibility: visible;
  }
}

.flex-container-row.wf-form-element-container {
  justify-content: flex-start;
  padding: 0px;
  & .wf-form-element-label {
    color: $chard;
  }
}

.flex-container-row.top-level{
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  & .headline-small {
    padding-bottom: 10px;
    padding-right: 20px;
    text-align: left;
  }
  &.justify-end {
    justify-content: flex-end;
  }
}

.flex-container-row.buttons {
  justify-content: center;
  width: 100%;
}

.flex-container-row.row-details {
  justify-content: space-between;
  width: 100%;
  border: 2px solid $chard;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.flex-container-row {
  & .body-short-form {
    padding: 0 0 .625rem 5px;
  }
}

.vendor-threshholds {
  & .body-short-form {
    padding: 0 10px .625rem 5px !important;
  }
}

.modal {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  z-index: 1000 !important;
  max-height: 70%;
  overflow: scroll;
}

.checkmark-image {
  margin-top: 4px;
}

.item-count {
  width: 100%;
  padding-bottom: 5px;
  justify-content: flex-end;
  font-size: 1.25em;
}

.sort-column {
  cursor: pointer;
}

.sort-order-image {
  padding-left: 15px;
}

svg.wf-icon {
  width: 100%
}

.primary-button {
  &.icon {
    padding: 5px 6px 0px 5px;
    margin: 0;
    color: white;
  }
}

.flex-container-column {
  & .wf-form-element-container {
    padding-right: 5px;
  }
}

.wf-select {
  &:disabled {
    background-color: $spoon;
  } 
}
